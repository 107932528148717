import { Link } from 'gatsby';
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { background, border } from '../colors';
import SettingsContext from './settingsContext';

const Menu = styled.div`
  display: none;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;

  @media (max-width: 425px) {
    display: flex;
  }
`;

const MenuIcon = styled.i`
  font-size: 40px !important;
`;

const Sidebar = styled.nav`
  background-color: ${background};
  color: white;
  position: fixed;
  height: 100vh;
  width: 200px;
  border-right: 8px solid ${border};
  font-family: 'Sorts Mill Goudy', serif;
  text-align: center;
  cursor: default;
  display: flex;
  flex-direction: column;
  top: 0;
  z-index: 1;

  @media (max-width: 425px) {
    height: 50px;
    width: 100vw;
    border-right: 0;
    border-bottom: 4px solid ${border};
  }
`;

const TitleLink = styled(Link)`
  cursor: pointer;
  display: block;
  color: white;
  text-decoration: none;
  user-select: none;
`;

const Pretitle = styled.div`
  margin: 20px 0 10px;
  font-size: 32px;

  @media (max-width: 425px) {
    display: none;
  }
`;

const Title = styled.div`
  font-size: 36px;
`;

const Spacer = styled.div`
  font-size: 120px;
  transform: scaleY(0.5);
  height: 60px;
  position: relative;
  bottom: 20px;
`;

const TopSpacer = styled(Spacer)`
  @media (max-width: 425px) {
    display: none;
  }
`;

const Tray = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 425px) {
    width: 200px;
    height: calc(100vh - 54px);
    border-right: 8px solid ${border};
    background-color: ${background};
    position: absolute;
    top: 54px;
    left: ${(props) => (props.opened ? '0' : '-208px')};
    transition: left 0.2s;
  }
`;

const Clickout = styled.div`
  @media (max-width: 425px) {
    width: 208px;
    height: calc(100vh - 54px);
    position: absolute;
    top: 54px;
    right: ${(props) => (props.opened ? '0' : '-208px')};
  }
`;

const MainTray = styled.div`
  flex: 1;
`;

const NavLink = styled(Link)`
  font-size: 30px;
  cursor: pointer;
  display: block;
  color: white;
  text-decoration: none;
  margin: 20px 0;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }

  &.active::before {
    content: "{ ";
  }

  &.active::after {
    content: " }";
  }
`;

const Setting = styled.div`
  font-size: 24px;
  cursor: pointer;
  color: white;
  text-decoration: none;
  margin: 20px 0;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Copyright = styled.div`
  font-size: 0.9em;
  padding-bottom: 8px;
`;

export default () => {
  const [trayOpened, setTrayOpened] = useState(false);
  const { toggleDark } = useContext(SettingsContext);

  const toggleTray = () => setTrayOpened(!trayOpened);
  const closeTray = () => setTrayOpened(false);

  const darkClick = () => {
    toggleDark();
    closeTray();
  };

  return (
    <Sidebar>
      <Menu onClick={toggleTray}>
        <MenuIcon className="material-icons">{trayOpened ? 'menu_open' : 'menu'}</MenuIcon>
      </Menu>
      <TitleLink to="/" onClick={closeTray}>
        <Pretitle>I am</Pretitle>
        <Title>G.S.M. Nui</Title>
      </TitleLink>
      <TopSpacer>~</TopSpacer>
      <Tray opened={trayOpened}>
        <MainTray>
          <NavLink to="/" activeClassName="active" onClick={closeTray}>Intro</NavLink>
          <NavLink to="/longwork" activeClassName="active" onClick={closeTray} partiallyActive>Long Work</NavLink>
          <NavLink to="/shortwork" activeClassName="active" onClick={closeTray} partiallyActive>Short Work</NavLink>
          <Spacer>~</Spacer>
          <Setting onClick={darkClick}>Dark Mode</Setting>
        </MainTray>
        <Copyright>
          &copy;
          {` Copyright ${new Date(Date.now()).getFullYear()} G.S.M. Nui`}
        </Copyright>
      </Tray>
      <Clickout opened={trayOpened} onClick={closeTray} />
    </Sidebar>
  );
};
