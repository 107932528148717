import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Sidebar from './sidebar';
import SettingsContext from './settingsContext';
import SEO from './seo';
import './fonts.css';

const Main = styled.main`
  width: calc(50% - 104px);
  /* 1px at the top because otherwise there's a margin from <p> and dark mode fails there */
  padding: 1px calc(25% - 52px) 0; 
  min-width: 450px;
  min-height: 100vh;
  margin-left: 208px;
  line-height: 1.5;
  font-family: 'EB Garamond', serif;

  background-color: ${(props) => (props.dark ? '#eeeeee' : 'white')};
  filter: ${(props) => (props.dark ? 'invert(100%)' : 'none')};

  @media (max-width: 425px) {
    width: 95%;
    padding: 2.5%;
    min-width: 0;
    margin-left: 0;
    margin-top: 54px;
  }

  @media (prefers-color-scheme: dark) {
    background-color: ${(props) => (props.dark ? 'white' : '#eeeeee')};
    filter: ${(props) => (props.dark ? 'none' : 'invert(100%)')};
  }
`;

// there's no real footer yet but the spacing is nice
const Footer = styled.footer`
  height: 200px;
`;

const Layout = ({ children }) => {
  const { dark } = useContext(SettingsContext);

  return (
    <>
      <SEO />
      <Sidebar />
      <Main dark={dark}>
        {children}
        <Footer />
      </Main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
