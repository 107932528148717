// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-longwork-js": () => import("./../../src/pages/longwork.js" /* webpackChunkName: "component---src-pages-longwork-js" */),
  "component---src-pages-shortwork-js": () => import("./../../src/pages/shortwork.js" /* webpackChunkName: "component---src-pages-shortwork-js" */),
  "component---src-templates-work-js": () => import("./../../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

