import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

const SettingsContext = createContext(
  {
    dark: false,
    toggleDark: () => { },
  },
);

const SettingsProvider = ({ children }) => {
  const [dark, setDark] = useState(false);

  const toggleDark = () => {
    setDark(!dark);
  };

  const state = { dark, toggleDark };

  return (
    <SettingsContext.Provider value={state}>
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SettingsContext;

export { SettingsProvider };
