import React from 'react';
import Layout from './src/components/layout';

import { SettingsProvider } from './src/components/settingsContext';

export const wrapRootElement = ({ element, props }) => (
  <SettingsProvider>
    <Layout {...props}>{element}</Layout>
  </SettingsProvider>
);
