import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
// import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        # defaultImage: image
        # twitterUsername
      }
    }
  }
`;

const SEO = ({
  title, description, // image, // article
}) => {
  // const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    // defaultImage,
    // twitterUsername,
  } = site.siteMetadata;

  const seo = {
    description: description || defaultDescription,
    // image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}`, // ${pathname}`,
  };

  return (
    <Helmet defaultTitle={defaultTitle} titleTemplate={titleTemplate}>
      <title>{title}</title>
      <meta name="description" content={seo.description} />
      {/* <meta name="image" content={seo.image} /> */}

      {seo.url && <meta property="og:url" content={seo.url} />}

      {/* {(article ? true : null) && <meta property="og:type" content="article" />} */}

      <meta property="og:title" content={title || defaultTitle} />

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {/* {seo.image && <meta property="og:image" content={seo.image} />} */}

      {/* <meta name="twitter:card" content="summary_large_image" />

      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && <meta name="twitter:image" content={seo.image} />} */}
      <link href="https://necolas.github.io/normalize.css/8.0.1/normalize.css" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
    </Helmet>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  // image: PropTypes.string,
  // article: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  description: null,
  // image: null,
  // article: false,
};
